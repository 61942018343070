<template>
  <div class="mcc_center con_area_bgcolor">
    <div class="mcc_center_con con_area">
      <div class="w11402">
        <h2>
          <span class="title">Mcct Convention Center</span>
        </h2>
        <div>
          <div class="mcc_center_introd justify_align">
            <p>
              MCCT has progressed alongside the community it serves. Throughout
              the three years of the Covid-19 pandemic, it utilized some social
              media platforms to provide its communication and outreach
              services. We managed to secure an office space which also serves
              as a venue for community gatherings, events and activities
              operated by MCCT. Therefore, in the following year, MCCT was able
              to utilize both online and in-person means to deliver its
              commsions.
            </p>
          </div>
          <div class="mcc_center_tree">
            <div class="w850">
              <div
                class="mcc_center_tree_con"
                v-for="(item, index) in treeslist"
                :key="index"
              >
                <div v-if="cWidth > 1024" class="mcc_center_leftandright">
                  <div v-if="index % 2 == 0">
                    <div class="mcc_center_tree_con_img">
                      <img
                        :src="
                          require('@/assets/img/mcc_center/' + item.imgName)
                        "
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="mcc_center_tree_con_desc justify_align">
                      <h2>{{ item.title }}</h2>
                      <span v-html="item.con"></span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="mcc_center_tree_con_desc justify_align">
                      <h2>{{ item.title }}</h2>
                      <span v-html="item.con"></span>
                    </div>
                    <div class="mcc_center_tree_con_img">
                      <img
                        :src="
                          require('@/assets/img/mcc_center/' + item.imgName)
                        "
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
                <div v-else class="mcc_center_topandbottom">
                  <div>
                    <div class="mcc_center_tree_con_desc justify_align">
                      <h2>{{ item.title }}</h2>
                      <span v-html="item.con"></span>
                    </div>
                    <div class="mcc_center_tree_con_img">
                      <img
                        :src="
                          require('@/assets/img/mcc_center/' + item.imgName)
                        "
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cWidth: sessionStorage.getItem("clientWidth"),
      treeslist: [
        {
          icon: "",
          imgName: "food.jpg",
          title: "Food",
          zwTtile: "美食",
          con: "Dim Sum, Snacks, Festival Foods, Signature dishes, Special Recommendations",
        },
        {
          icon: "",
          imgName: "tea.jpg",
          title: "Wellbeing",
          zwTtile: "保健",
          con: "Tea, soup, juice, Herb tea",
        },
        {
          icon: "",
          imgName: "xiangqi.jpg",
          title: "Games",
          zwTtile: "游戏",
          con: "Puzzles, Poker, Mahjong, Chess, Go, Balls, Karaoke,Werewolf, Online Games, TV, Stories",
        },
        {
          icon: "",
          imgName: "speakchinese.jpg",
          title: "Education",
          zwTtile: "培训",
          con: "Language NCEA Chinese exam / crash travel practical  Chinese / life Chinese new comers' English/English for  work <br/> Recreation Tai Chi / Calligraphy / Painting / Dance  / Musical Instruments / Fitness <br/> Skills  driving/cooking/gardening/sewing/employment interview/accounting <br/> Workshop Insurance / Welfare /  Security / Psychology / Domestic violence / Health /  Business/ Pension / Tax / Immigration",
        },
        {
          icon: "",
          imgName: "tuangou.jpg",
          title: "Goods",
          zwTtile: "交易",
          con: "Second hand, group purchasing, fairs",
        },
        {
          icon: "",
          imgName: "meirong.jpg",
          title: "Service",
          zwTtile: "服务",
          con: "Haircut, Beauty, Skin Care, Foot Reflexology, Massage,  Scraping, Moxibustion, Physical Examination, Psychological  Testing, House help",
        },
        {
          icon: "",
          imgName: "lvxing.jpg",
          title: "Referrer",
          zwTtile: "推荐",
          con: "Insurance, Laws, Welfare, Public Security, Finance,  Travel Agency, Study Abroad, Immigration, Recruitment,  health Care, Pension, Translation",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style>
.mcc_center {
  width: 100%;
}
.mcc_center_con {
  width: 100%;
}
.mcc_center_con .w11402 {
  margin: 0 auto;
  overflow: hidden;
}
.mcc_center_con .w11402 > h2 {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #ccc;
  line-height: 0.1em;
  margin: 4rem 0 5rem;
}
.mcc_center_con h2 > span {
  background: #fafafa;
  padding: 0 10px;
  box-sizing: border-box;
  font-weight: 600;
}
.mcc_center_introd > img {
  width: 100%;
  height: auto;
}
.mcc_center_introd > p {
  font-size: 1.8rem;
  line-height: 150%;
}
.mcc_center_tree {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.w850 {
  min-width: 900px;
}
.mcc_center_tree-item {
  width: 33%;
  height: 300px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.09);
}
.mcc_center_tree_con {
  width: 100%;
  /* height: 290px; */
  margin-bottom: 1rem;
}
.mcc_center_tree_con .mcc_center_leftandright > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.mcc_center_tree_con_img {
  width: 50%;
  height: 100%;
  position: relative;
}
.mcc_center_tree_con_img > img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
.mcc_center_tree_con_desc {
  /* max-width: 50%; */
  width: 50%;
  font-size: 1.9rem;
  padding: 1rem;
  box-sizing: border-box;
}
.mcc_center_tree_con_desc > h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: #0a101d;
}
.mcc_center_tree_con .mcc_center_topandbottom > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.mcc_center_tree_con .mcc_center_topandbottom > div .mcc_center_tree_con_img {
  width: 100%;
  margin: 10px 0;
}
.mcc_center_tree_con .mcc_center_topandbottom > div .mcc_center_tree_con_desc {
  width: 100%;
}
.mcc_center_tree_desc {
  color: #04bde4;
  margin-bottom: 20px;
}
</style>